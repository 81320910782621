import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shang-pin-xiao-shou-mx/list',
        method: 'post',
        data
    })
}

//获取商品销售汇总列表
export function getShangPinXSHZ(data) {
    return request({
        url: '/shang-pin-xiao-shou-mx/get-shang-pin-xs-hz',
        method: 'post',
        data
    })
}

//根据收款明细id获取商品销售详情列表
export function getListByParentId(id) {
    return request({
        url: `/shang-pin-xiao-shou-mx/list-by-parent-id/${id}`,
        method: 'get',
    })
}

//畅销商品列表
export function getChangXiaoList(data) {
    return request({
        url: '/shang-pin-xiao-shou-mx/get-chang-xiao-list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/shang-pin-xiao-shou-mx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/shang-pin-xiao-shou-mx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shang-pin-xiao-shou-mx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shang-pin-xiao-shou-mx/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shang-pin-xiao-shou-mx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shang-pin-xiao-shou-mx/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shang-pin-xiao-shou-mx-list',
    component: () => import('@/view/jygl/skmx/ShangPinXiaoShouMXList'),
    name: 'ShangPinXiaoShouMXList',
    meta: {title: '商品销售明细', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"商品销售明细基础列表","methodUrl":"/shang-pin-xiao-shou-mx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"商品销售明细添加保存","methodUrl":"/shang-pin-xiao-shou-mx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"商品销售明细获取编辑数据","methodUrl":"/shang-pin-xiao-shou-mx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"商品销售明细获取详情数据","methodUrl":"/shang-pin-xiao-shou-mx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"商品销售明细编辑保存","methodUrl":"/shang-pin-xiao-shou-mx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"商品销售明细删除","methodUrl":"/shang-pin-xiao-shou-mx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"商品销售明细批量删除","methodUrl":"/shang-pin-xiao-shou-mx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
