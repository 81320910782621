<template>
    <div class="x-card" :class="xCardClass">
        <div class="x-card-title-before"></div>
        <div class="x-card-title">{{label}}</div>
        <div class="x-card-text">{{text}}</div>
        <div class="x-card-icon" :class="icon"></div>
    </div>
</template>

<script>
    import "./xcard.scss";

    export default {
        name: "XCard",
        props: {
            label: {
                type: String,
                required: true
            },
            text: {
                type: [String, Number, Object],
                required: true
            },
            bg: {
                type: String,
                default: 'lan'
            },
            icon: String,
            w3: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            xCardClass() {
                return this.bg + " " + (this.w3 ? 'w3' : '');
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
